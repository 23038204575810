var pomlo_app = {};

// Define constants
pomlo_app.BREAKPOINTS = {
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1140
};
pomlo_app.DEFAULT_DURATION = 300;
pomlo_app.HEADER_HEIGHT = 82;
pomlo_app.HEADER_HEIGHT_MD = 106;
pomlo_app.HEADER_HEIGHT_LG = 132;

pomlo_app.toggleHeaderStyle = function () {
  var navbarContainer = jQuery('#navbar-container');
  var mainMenuContainer = jQuery('#main-menu');
  if (jQuery(window).width() > pomlo_app.BREAKPOINTS.MD && jQuery(window).width() <= pomlo_app.BREAKPOINTS.LG) {
    navbarContainer.removeClass('navbar-container--small');
    mainMenuContainer.removeClass('main-menu--small');
    navbarContainer.addClass('navbar-container--medium');
    mainMenuContainer.addClass('main-menu--medium');
  } else if (jQuery(window).width() <= pomlo_app.BREAKPOINTS.MD) {
    navbarContainer.addClass('navbar-container--small');
    mainMenuContainer.addClass('main-menu--small');
    navbarContainer.removeClass('navbar-container--medium');
    mainMenuContainer.removeClass('main-menu--medium');
  } else {
    navbarContainer.removeClass('navbar-container--small');
    navbarContainer.removeClass('navbar-container--medium');
    mainMenuContainer.removeClass('main-menu--small');
    mainMenuContainer.removeClass('main-menu--medium');
  }
};
pomlo_app.debouncedToggleHeaderStyle = debounce(pomlo_app.toggleHeaderStyle, 100);

pomlo_app.getHeaderHeight = function () {
  var container = jQuery('#navbar-container');
  if (container.hasClass('navbar-container--small')) {
    return pomlo_app.HEADER_HEIGHT;
  }
  if (container.hasClass('navbar-container--medium')) {
    return pomlo_app.HEADER_HEIGHT_MD;
  }
  return pomlo_app.HEADER_HEIGHT_LG;
}

pomlo_app.setHomeIntroductionImageCss = function () {
  var homeIntroSquareBackground = jQuery('#home-introduction-image__background');
  var homeIntroImage = jQuery('#home-introduction-image__image');
  // Not using jQuery to use getBoundingClientRect when needed
  var homeIntroImageJS = document.getElementById('home-introduction-image__image');
  if (homeIntroSquareBackground.length) {
    var homeIntroImageHeight = homeIntroImage.height();
    var homeIntroImageLeftPosition = homeIntroImageJS.getBoundingClientRect().left + window.scrollX;
    var homeIntroLogoImg = jQuery('#home-introduction-image__logo').find('img');

    if (window.innerWidth >= pomlo_app.BREAKPOINTS.LG) {
      homeIntroSquareBackground.css('height', homeIntroImageHeight - 20);
      homeIntroSquareBackground.css('margin-bottom', homeIntroImageHeight / 3);
    } else if (homeIntroSquareBackground.css('height') !== "50vh") {
      homeIntroSquareBackground.css('height', "40vh");
      homeIntroSquareBackground.css('margin-bottom', 0);
    }

    homeIntroLogoImg.css('top', homeIntroImageHeight - (homeIntroImageHeight / 1.5));
    var homeIntroLogoImgLeft = homeIntroImageLeftPosition - (homeIntroLogoImg.width() / 1.8);
    homeIntroLogoImg.css('left', homeIntroLogoImgLeft > 0 ?
      homeIntroImageLeftPosition - (homeIntroLogoImg.width() / 1.8) :
      0
    );
  }
};

pomlo_app.calculateActivePanel = function () {
  var windowTopPosition = document.documentElement.scrollTop;
  jQuery('.panel').each(function () {
    var scrollLinkToUpdate = jQuery('.scroll-navbar').find("[data-link='#" + jQuery(this).prop('id') + "']");
    var panelTopPosition = jQuery(this).offset().top - pomlo_app.getHeaderHeight();
    var panelBottomPosition = panelTopPosition + jQuery(this).outerHeight();

    if (windowTopPosition >= panelTopPosition && windowTopPosition < panelBottomPosition) {
      scrollLinkToUpdate.addClass('active');
    } else if (scrollLinkToUpdate.hasClass('active')) {
      scrollLinkToUpdate.removeClass('active');
    }
  });
}

pomlo_app.openCalendar = function () {
  const element = jQuery('#bookingCalendarToggle');
  element.addClass('bookingCalendarToggle_opened');
  element.find('.icon').addClass('icon__rotate');
}

pomlo_app.scrollToCalendar = function (isContact = false) {
  const calendarSection = isContact ? jQuery('#contactBookingSection') : jQuery('#bookingCalendarContainer');
  if (calendarSection) {
    const sectionTop = jQuery(calendarSection).offset().top;
    jQuery('html,body').animate({scrollTop: sectionTop - pomlo_app.getHeaderHeight()}, 'slow');
  }
}


jQuery(document).ready(function (jQuery) {
  // Init interface

  if (localStorage.getItem("scrollToCalendar") !== null && jQuery('#contactBookingSection').length) {
    pomlo_app.scrollToCalendar(true);
    localStorage.removeItem("scrollToCalendar");
  }

  // Home intro
  pomlo_app.setHomeIntroductionImageCss();

  pomlo_app.toggleHeaderStyle();

  if (jQuery(".news-images").length) {
    jQuery(".news-images").imagesLoaded(function () {
      jQuery('.grid').masonry({
        itemSelector: '.grid-item',
        columnWidth: '.grid-sizer',
        percentPosition: true,
        horizontalOrder: true
      });
    });
  }

  // Loader
  jQuery('.img-loader-wait').imagesLoaded(function () {
    console.log('Images loaded');
    jQuery('.img-bg-loader-wait').imagesLoaded({background: true}, function () {
      console.log('Background image loaded');

      var delay = document.location.host ? 0 : 500;
      setTimeout(function () {
        jQuery("#loader").fadeOut();
      }, delay);
    });
  });

  // Menu button
  jQuery('#btn-menu-open').click(function () {
    jQuery('.main-menu').addClass('main-menu--active');

    if (jQuery('#navbar-header').hasClass('header--small')) {
      jQuery('#navbar-header').addClass('header--small--lock');
    }

    var scrollY = jQuery(window).scrollTop();
    jQuery('body').css({
      'position': 'fixed',
      'top': '-' + scrollY + 'px'
    });
  });

  jQuery('#btn-menu-close').click(function () {
    jQuery('.main-menu').removeClass('main-menu--active');
    jQuery('#navbar-header').removeClass('header--small--lock');

    var scrollY = jQuery('body').css('top');
    jQuery('body').css({
      'position': '',
      'top': ''
    });
    window.scrollTo({top: parseInt(scrollY || '0') * -1, left: 0, behavior: 'instant'})
  });

  var pagePanels = jQuery('.panel');
  if (pagePanels.length) {
    pagePanels.each(function () {
      jQuery('#scroll-navbar').append('<a class="scroll-navbar__dot scroll-to" data-link="#' + jQuery(this).attr('id') + '"></a>');
    });
  }

  jQuery('.scroll-navbar__dot').click(function () {
    jQuery('.scroll-navbar__dot').each(function () {
      jQuery(this).removeClass('active');
    });
    jQuery(this).addClass('active');
  });

  jQuery('.scroll-to').click(function () {
    const link = jQuery(this).attr('data-link');
    const sectionTop = jQuery(link).offset().top;
    jQuery('html,body').animate({scrollTop: sectionTop - pomlo_app.getHeaderHeight()}, 'slow');
  });

  pomlo_app.calculateActivePanel();

  jQuery('#bookingCalendar').hide();

  jQuery('#bookingCalendarToggle').click(function () {
    jQuery('#bookingCalendar').slideToggle({"opacity": "show", top: "100"}, 500);
    if (jQuery(this).hasClass('bookingCalendarToggle_opened')) {
      jQuery(this).removeClass('bookingCalendarToggle_opened');
      jQuery(this).find('.icon').removeClass('icon__rotate');
    } else {
      pomlo_app.openCalendar();
    }
  });

  jQuery('#bookingCalendarOpen, #bookingCalendarOpenMobile').click(function () {
    if (jQuery('#contactBookingSection').length) {
      pomlo_app.scrollToCalendar(true);
    } else if (!jQuery('#bookingCalendar').length) {
      localStorage.setItem("scrollToCalendar", "true");
      window.location.href = contactPageLink;
    } else {
      jQuery('#bookingCalendar').slideDown({"opacity": "show", top: "100"}, 500);
      pomlo_app.scrollToCalendar();
      pomlo_app.openCalendar();
    }
  })

  const resourceFilesToHide = jQuery('.resourceFile[data-hide="true"]');

  if(resourceFilesToHide.length){
    resourceFilesToHide.each(function () {
      jQuery(this).hide();
    });

    jQuery('.wpcf7').on('wpcf7mailsent', function (event) {
      jQuery('.resourceForm').each(function () {
        jQuery(this).slideUp(400, function () {
          resourceFilesToHide.each(function () {
            jQuery(this).slideDown();
          });
        });
      });
    });
  }
});

jQuery(window).scroll(function () {
  pomlo_app.calculateActivePanel();
});

jQuery(window).resize(function () {
  // Home intro
  pomlo_app.setHomeIntroductionImageCss();
  pomlo_app.debouncedToggleHeaderStyle();
});

jQuery(document).on('DOMNodeInserted', function (e) {
  const textColor = bookingSectionColor ? bookingSectionColor : '1';
  if (e.target.className === 'pbSubmit') {
    jQuery('.pbSubmit').addClass(`button button--color-${textColor}`);
    jQuery('.captcha').appendTo('.right-col');
    jQuery('.pbSubmit').appendTo('.right-col');
  }

  if (e.target.className === 'ui-datepicker-calendar') {
    jQuery('.ahb_m2 #fbuilder .ui-datepicker-calendar .ui-state-active').addClass(`color-bg--${textColor}`);
    jQuery('.ahb_m2 #fbuilder td:not(.ui-datepicker-unselectable) a.ui-state-default').addClass(`color-bg-hover--${textColor}`);
    jQuery('.ahb_m2 #fbuilder .slots div a').addClass(`color-border--${textColor}`);
    jQuery('.ahb_m2 #fbuilder .slots div:not(.htmlUsed) a').addClass(`color-bg-hover--${textColor}`);
  }

  if (e.target.className === 'ahbfield_service' && bookingSectionServicesArray?.length) {
    jQuery(".ahbfield_service").find('option:contains("Special")').remove();
    if (!bookingSectionServicesArray.includes('health')) {
      jQuery(".ahbfield_service").find('option:contains("Bilan coaching")').remove();
      jQuery(".ahbfield_service").find('option:contains("Séance de travail ou de suivi")').remove();
      jQuery(".ahbfield_service").find('option:contains("Point de situation coaching")').remove();
    }
    if (!bookingSectionServicesArray.includes('nurse')) {
      jQuery(".ahbfield_service").find('option:contains("Bilan infirmière coordinatrice")').remove();
      jQuery(".ahbfield_service").find('option:contains("Séance de coordination, suivi ou d’ETP")').remove();
      jQuery(".ahbfield_service").find('option:contains("Point de situation infirmière")').remove();
    }
    if (!bookingSectionServicesArray.includes('hypnosis')) {
      jQuery(".ahbfield_service").find('option:contains("Bilan hypnose - ancrage")').remove();
      jQuery(".ahbfield_service").find('option:contains("Séance d’hypnose")').remove();
      jQuery(".ahbfield_service").find('option:contains("Séance d’hypnose de")').remove();
    }

  }
});
